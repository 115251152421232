<template lang="pug">
include ../../../../configs/template
v-card.p-1
  div.mt-7.ml-7
    +radio-group('statementType','statementTypeList', '')(row)
  TableV2(
    :isLoading="isLoad"
    :headers="headers"
    :items="items.results || []"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="statementSearch"
    :actions="actions"
    )
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CrewingStatement',
  data () {
    return {
      checkAccess,
      isLoad: true,
      statementType: this.$route.query.statement_type || 'connect',
      statementTypeList: [
        { label: this.$t('statementsAttach'), value: 'connect' },
        { label: this.$t('statementsDetach'), value: 'detach' }
      ],
      headers: [
        { value: 'id', text: this.$t('number'), sortable: false },
        { value: 'userFullName', text: this.$t('fullName'), sortable: false },
        { value: 'created_at', text: this.$t('createDate') },
        { value: 'crewing', text: this.$t('crewing'), sortable: false },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), sortable: false, align: 'center' }
      ],
      actions: []
    }
  },
  computed: {
    ...mapState({
      items: state => state.crewing.crewingStatements,
      crewingManagers: state => state.crewing.crewingManagers
    }),
    queryStatementType () {
      return this.$route.query.statement_type
    }
  },
  watch: {
    statementType (val) {
      const query = { ...this.$route.query }
      if (val === 'detach') query.statement_type = val
      else delete query.statement_type

      this.$router.push({ query })
      this.statementSearch({ ...this.$route.query })
      this.updateActions()
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'crewingStatement', access: checkAccess('statementISC') })
  },
  mounted () {
    this.getCrewingCompanyManagers()
    if (this.queryStatementType) this.statementType = 'detach'
    this.updateActions()
  },
  methods: {
    ...mapActions([
      'getCrewingStatementList',
      'getCrewingDetachStatementList',
      'getCrewingCompanyManagers'
    ]),
    updateActions () {
      const query = this.queryStatementType ? { statement_type: 'detach' } : {}
      this.actions = [{
        id: 1,
        tooltip: 'tooltip.go',
        to: ({ id }) => ({ name: `crewing-statement-info`, query, params: { id } }),
        color: 'gray',
        name: 'mdi-arrow-right'
      }]

      this.headers.splice(3, 1, {
        value: this.queryStatementType ? 'manager' : 'crewing',
        text: this.$t(this.queryStatementType ? 'crewingManager' : 'crewing'),
        sortable: false
      })
    },
    async statementSearch (filter) {
      this.isLoad = true
      const methodName = this.statementType === 'detach'
        ? 'getCrewingDetachStatementList' : 'getCrewingStatementList'
      await this[methodName](filter)
      this.isLoad = false
    }
  }
}
</script>
